import classNames from 'classnames';

import useIsAgora from '@/hooks/useIsAgora';
import useIsGovernancePage from '@/hooks/useIsGovernancePage';
import useIsLiquidStaking from '@/hooks/useIsLiquidStaking';
import useIsMarketsPage from '@/hooks/useIsMarketsPage';
import useTheme from '@/hooks/useTheme';

const Title: React.FC<any> = ({ children, className }) => {
  const isGovernancePage = useIsGovernancePage();
  const isMarketPage = useIsMarketsPage() || isGovernancePage;
  const agora = useIsAgora() || isMarketPage;
  const liquidStaking = useIsLiquidStaking();
  const { isLight } = useTheme();

  return (
    <h3
      className={classNames(
        className,
        (agora || liquidStaking) && isLight ? 'text-[#06021F]' : 'text-white',
        'text-sm font-semibold leading-tight tracking-wide'
      )}
    >
      {children}
    </h3>
  );
};

export default Title;
