import classNames from 'classnames';
import { domAnimation, LazyMotion, m } from 'framer-motion';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';

import useIsAgora from '@/hooks/useIsAgora';

import Hint from '@/components/Hint';

import { useAppDispatch } from '@/store';
import { setDrawer } from '@/store/drawer';

import { slugify } from '@/utils/helpers';

type ProductItemProps = {
  title: string;
  description: string;
  url: string;
  active?: boolean;
  forceLabel?: string;
  png?: string;
};

const ProductItem = ({
  title,
  description,
  url,
  active,
  forceLabel,
  png,
}: ProductItemProps) => {
  const live = active || url !== '#';
  const { t } = useTranslation();
  const agora = useIsAgora();
  const router = useRouter();
  const dispatch = useAppDispatch();

  return (
    <LazyMotion features={domAnimation}>
      <m.div
        onTap={() => {
          if (!url) return;
          router.push(url);
          dispatch(setDrawer({ isOpen: false }));
        }}
      >
        <div className={classNames('block', { pointer: !!url })}>
          <Hint
            block
            content={
              <span className='text-[10px] font-semibold leading-none tracking-wide'>
                {t('coming-soon')}
              </span>
            }
            layerStyle={{
              display: 'inline-flex',
              padding: '6px',
              borderRadius: 6,
              translate: '-10px 0',
            }}
            unvisible={live}
            backgroundColor='#030303'
            placement='top-end'
            arrow={false}
            offset={-10}
          >
            <div
              className={classNames(
                'flex h-14 items-start gap-4 rounded-[8px] p-2 font-normal transition-colors'
                // agora
                //   ? 'hover:bg-[#E9E9E9]/50 hover:dark:bg-[#181640]/50'
                //   : 'hover:bg-[#06192D]/10 hover:dark:bg-[#06192D]'
              )}
            >
              <div
                className={classNames(
                  agora ? 'bg-[#E9E9E9] dark:bg-[#181640]' : 'bg-[#0B2346]',
                  'inline-block h-10 w-10 rounded-[8px]'
                )}
              >
                <img
                  className='h-full w-full'
                  src={`https://cdn.app.hatom.com/images/mega/${slugify(title)}.${png ? 'png' : 'svg'}`}
                  alt={title}
                />
              </div>
              <div className='flex-1'>
                <h2
                  className={classNames(
                    agora ? 'text-agora' : 'text-[#2A3E6C]',
                    'dark:text-white',
                    'font-semibold leading-6'
                  )}
                >
                  {forceLabel ?? title}
                </h2>
                <p
                  className={classNames(
                    agora && 'text-[#393646] dark:text-[#6970B5]',
                    'text-xs leading-tight'
                  )}
                >
                  {description}
                </p>
              </div>
            </div>
          </Hint>
        </div>
      </m.div>
    </LazyMotion>
  );
};

export default ProductItem;
