import React from 'react';

const DiscordIcon = () => {
  return (
    <svg
      width={15.17}
      height={17.1}
      fill='currentColor'
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 15.17 17.1'
    >
      <path d='M6.69 8.31a1.07 1.07 0 0 1-1 1.13 1.08 1.08 0 0 1-1-1.13 1.07 1.07 0 0 1 1-1.13 1.06 1.06 0 0 1 1 1.13zm3.67 0a1.07 1.07 0 0 1-1 1.13 1.08 1.08 0 0 1-1-1.13 1.07 1.07 0 0 1 1-1.13 1.06 1.06 0 0 1 1 1.13z' />
      <path d='M12.83 0H2.34A2.33 2.33 0 0 0 0 2.33v10.53a2.33 2.33 0 0 0 2.34 2.33h7.82l5 1.91V2.33A2.33 2.33 0 0 0 12.83 0zM13 10.73a9.23 9.23 0 0 1-2.8 1.44 8.14 8.14 0 0 1-.6-1 6.41 6.41 0 0 0 1-.46l-.23-.18a6.45 6.45 0 0 1-5.59 0l-.23.18a5.85 5.85 0 0 0 .94.46 5.71 5.71 0 0 1-.6 1 9 9 0 0 1-2.8-1.43 9.61 9.61 0 0 1 1.64-6.4A9.32 9.32 0 0 1 6 3.61c.1.18.22.42.3.61a8.25 8.25 0 0 1 2.53 0 4.85 4.85 0 0 1 .29-.61 8.91 8.91 0 0 1 2.28.72 9.54 9.54 0 0 1 1.6 6.4z' />
    </svg>
  );
};

export default DiscordIcon;
