import classNames from 'classnames';

type LampProps = {
  on: boolean;
  className?: string;
};

export function Lamp({ on, className = 'w-1.5 h-1.5' }: LampProps) {
  return (
    <div
      className={classNames(className, 'rounded-full', {
        'bg-[#3D926A] [box-shadow:0px_0px_3px_0.2px_#3D926A]': on,
        'bg-[#C66A35] [box-shadow:0px_0px_3px_0.2px_#AE6033]': !on,
      })}
    />
  );
}
