import classNames from 'classnames';
import { useTranslation } from 'next-i18next';
import { useToggle } from 'usehooks-ts';

import useIsAgora from '@/hooks/useIsAgora';
import useIsGovernancePage from '@/hooks/useIsGovernancePage';
import useIsLiquidStaking from '@/hooks/useIsLiquidStaking';
import useIsMarketsPage from '@/hooks/useIsMarketsPage';
import useTheme from '@/hooks/useTheme';

import CustomLink from '@/components/CustomLink';
import Hint from '@/components/Hint';

import clsxm from '@/services/clsxm';
import { slugify } from '@/utils/helpers';

import ArrowRight from '../Icons/ArrowRight';
import Title from './Title';

const ProductItem: React.FC<any> = ({
  title,
  description,
  url,
  active,
  force,
  png,
}) => {
  const [hoverOn, toggle] = useToggle();
  const live = active || url !== '#';
  const { t } = useTranslation();
  const { isLight } = useTheme();
  const isGovernancePage = useIsGovernancePage();
  const isMarketPage = useIsMarketsPage();
  const agora = useIsAgora() || (isLight && (isGovernancePage || isMarketPage));
  const liquidStaking = useIsLiquidStaking();

  return (
    <div className='block' onMouseEnter={toggle} onMouseLeave={toggle}>
      <CustomLink href={url}>
        <Hint
          block
          content={
            <span className='text-[10px] font-semibold leading-none tracking-wide'>
              {t('coming-soon')}
            </span>
          }
          layerStyle={{
            display: 'inline-flex',
            padding: '6px',
            borderRadius: 6,
            translate: '-10px 0',
          }}
          unvisible={live}
          backgroundColor='#030303'
          placement='top-end'
          arrow={false}
          offset={-10}
        >
          <div
            className={classNames(
              { 'cursor-default': !live },
              'flex items-start gap-2.5 rounded-[8px] p-2 font-normal transition-colors',
              liquidStaking
                ? isLight
                  ? 'hover:bg-[#EEEBF8]'
                  : 'hover:bg-[#45427066]'
                : agora
                ? isLight
                  ? 'hover:bg-[#ECEBEB]'
                  : 'hover:bg-[#090C2E]'
                : 'hover:bg-[#06192D]',
            )}
          >
            <div
              className={classNames(
                'inline-block rounded-[8px]',
                liquidStaking
                  ? 'bg-white dark:bg-[#1F1B3D]'
                  : agora
                  ? isLight
                    ? hoverOn
                      ? 'bg-[#D6D5D5]'
                      : 'bg-[#E8E8E8]'
                    : 'bg-[#060825]'
                  : 'bg-[#021321]',
              )}
            >
              <img
                className={clsxm('h-[35px] w-[35px]')}
                src={`https://cdn.app.hatom.com/images/mega/${force ? force : slugify(title)}.${
                  png ? 'png' : 'svg'
                }`}
                alt={title}
              />
            </div>
            <div className='flex-1 space-y-1'>
              <div className='flex items-center justify-between gap-1'>
                <Title>{title}</Title>
                <ArrowRight
                  className={classNames(
                    'w-auto text-[#43A077] transition-opacity',
                    hoverOn ? 'opacity-100' : 'opacity-0',
                  )}
                />
              </div>
              <p>{description}</p>
            </div>
          </div>
        </Hint>
      </CustomLink>
    </div>
  );
};

export default ProductItem;
