import Link from 'next/link';
import { useRouter } from 'next/router';

import { links } from '@/config/envVars';

type ContainerProps = {
  children: React.ReactNode;
  url: string;
  external?: boolean;
};

const Container = ({ children, url, external = false }: ContainerProps) => {
  const router = useRouter();

  if (url && !external) {
    const href = `${links.hatomUrl}/${router.locale}${url}`;

    return <Link legacyBehavior href={href}>{children}</Link>;
  }

  if (external) {
    return <Link legacyBehavior href={url}>{children}</Link>;
  }

  return <>{children}</>;
};

export default Container;
