import { useRouter } from 'next/router';

import { ROUTES } from '@/types/enums';

const useIsLiquidStaking = () => {
  const path_expectations = [
    ROUTES.LIQUID,
    '/liquidstaking',
    '/liquid-staking',
    '/become-hatom-operator',
    '/liquid/become-an-operator',
    ROUTES.LIQUID_APP,
    ROUTES.LIQUID_MULTIVERSX_APP,
  ];
  const { pathname } = useRouter();
  return path_expectations.includes(pathname);
};

export const useIsLiquidStakingApp = () => {
  const path_expectations = [
    ROUTES.LIQUID_APP as string,
    ROUTES.LIQUID_MULTIVERSX_APP as string,
    ROUTES.LIQUID_TAO_APP as string,
  ];
  const { pathname } = useRouter();
  return path_expectations.includes(pathname);
};

export default useIsLiquidStaking;
