import React from 'react';

const MediumIcon = () => {
  return (
    <svg
      width='10'
      height='11'
      viewBox='0 0 10 11'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M9.02864 10.9074V4.24797C9.02828 3.40776 8.77886 2.58651 8.31192 1.888C7.84497 1.18949 7.18145 0.645061 6.4052 0.323518C5.62895 0.00197442 4.7748 -0.0822571 3.9507 0.0814668C3.12659 0.245191 2.36951 0.649523 1.77512 1.24337C1.5565 1.46188 1.33788 1.74204 1.16904 2.00573C1.16085 2.01851 1.15278 2.03118 1.14493 2.04385L1.14213 2.04845C0.73974 2.71142 0.52779 3.47244 0.529552 4.24797V10.9084H9.02954L9.02864 10.9074Z'
        fill='#1A183F'
      />
    </svg>
  );
};

export default MediumIcon;
