import classNames from 'classnames';
import { useEffect } from 'react';

import css from '../../styles.module.css';

import useIsAgora from '@/hooks/useIsAgora';
import useScrollBlock from '@/hooks/useScrollBlock';

import BottomLink from '@/components/Header/components/MenuDrawer/components/BottomLink';
import Products from '@/components/Header/components/MenuDrawer/components/Products';
import { USEFUL_LINKS } from '@/components/Header/constants';
import AppsList from '@/components/MoreApps/components/AppsList';

import { useAppSelector } from '@/store';
import { drawerSelector } from '@/store/drawer';

export default function MenuDrawer() {
  const { isOpen } = useAppSelector(drawerSelector);

  const [blockScroll, allowScroll] = useScrollBlock();

  useEffect(() => {
    if (isOpen) {
      blockScroll();
    } else {
      allowScroll();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const LINKS = USEFUL_LINKS.filter((e) => e.hiddenOn !== 'menudrawer');

  const agora = useIsAgora();

  return (
    <>
      {isOpen && (
        <div
          className={classNames(
            'header-phone-bk:hidden',
            'h-20',
            'fixed inset-x-0 top-0 z-[81]',
            '[background-image:linear-gradient(to_bottom,#FBFBFD_50%,_transparent_100%)]',
            agora
              ? 'dark:[background-image:linear-gradient(to_bottom,#0C0B21_50%,_transparent_100%)]'
              : 'dark:[background-image:linear-gradient(to_bottom,#031425_50%,_transparent_100%)]',
            'pointer-events-none cursor-none overflow-hidden',
            'transition-all duration-300 ease-out'
          )}
        />
      )}
      <div
        className={classNames(
          css.menuDrawerZ,
          'header-phone-bk:hidden',
          'fixed inset-0 h-full w-full',
          'flex flex-col gap-8',
          'pt-[13.8vh]',
          'bg-[#FBFBFD]',
          agora
            ? 'dark:[background-image:linear-gradient(#0C0B21,#0C0B21)]'
            : 'dark:[background-image:linear-gradient(154.92deg,#132878_-26.82%,#03192F_-1.68%,#021326_42.91%,#0D2A48_139.63%)]',
          'text-[#53648C]',
          'transition-all duration-300',
          'overflow-scroll',
          !isOpen ? '-translate-y-full' : '-translate-y-0'
        )}
      >
        <Products />

        <div
          className={classNames(
            'mx-auto w-64 rounded-xl',
            agora
              ? [
                  'text-agora [background-image:linear-gradient(123.59deg,#E2E2E2_-1.97%,#E9E9E9_107.18%)]',
                  'dark:text-white dark:[background-image:linear-gradient(123.59deg,#252081_-1.97%,#000929_107.18%)]',
                ]
              : 'text-white [background-image:linear-gradient(115.38deg,#272B68_3.97%,#13484B_93.21%)]'
          )}
        >
          <AppsList />
        </div>
        <div className='mt-auto mb-5 flex justify-between px-5'>
          {LINKS.map((item, i) => {
            if (item.name.toLowerCase().includes('governance')) return <></>;

            return (
              <div
                className='-mx-2 text-xs font-semibold leading-tight'
                key={item.name}
              >
                <BottomLink {...item} />
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
}
