import React from 'react';

const ExternalLinkIcon = ({
  size = 12,
  className = '',
  href = '#',
  target = '_blank',
}) => {
  function Content() {
    return (
      <>
        <svg
          width={size}
          height={size}
          viewBox='0 0 12 12'
          fill='currentColor'
          className={`inline-block ${className}`}
          xmlns='http://www.w3.org/2000/svg'
        >
          <path d='M5.90388 11.0024C1.20202 11.0024 0.408203 9.4013 0.408203 5.80797C0.408203 2.21464 1.11654 0.613525 5.29325 0.613525C5.4552 0.613525 5.61052 0.67791 5.72503 0.792516C5.83955 0.907121 5.90388 1.06256 5.90388 1.22464C5.90388 1.38671 5.83955 1.54215 5.72503 1.65676C5.61052 1.77136 5.4552 1.83575 5.29325 1.83575C2.06301 1.83575 1.62947 2.6363 1.62947 5.80797C1.62947 8.97964 2.0508 9.78019 5.90388 9.78019C9.75697 9.78019 10.1783 8.97353 10.1783 5.80797C10.1783 5.64589 10.2426 5.49046 10.3572 5.37585C10.4717 5.26124 10.627 5.19686 10.7889 5.19686C10.9509 5.19686 11.1062 5.26124 11.2207 5.37585C11.3352 5.49046 11.3996 5.64589 11.3996 5.80797C11.3996 9.4013 10.6057 11.0024 5.90388 11.0024Z' />
          <path d='M6.51407 5.5025C6.43371 5.50296 6.35405 5.48755 6.27965 5.45714C6.20525 5.42673 6.13758 5.38192 6.08053 5.32528C6.02329 5.26847 5.97787 5.20088 5.94686 5.12641C5.91586 5.05194 5.8999 4.97206 5.8999 4.89139C5.8999 4.81071 5.91586 4.73084 5.94686 4.65637C5.97787 4.5819 6.02329 4.51431 6.08053 4.4575L10.3549 0.179723C10.4699 0.0646482 10.6259 0 10.7885 0C10.9511 0 11.107 0.0646482 11.222 0.179723C11.337 0.294797 11.4016 0.450872 11.4016 0.613611C11.4016 0.776351 11.337 0.932426 11.222 1.0475L6.94762 5.32528C6.89056 5.38192 6.8229 5.42673 6.7485 5.45714C6.6741 5.48755 6.59444 5.50296 6.51407 5.5025Z' />
          <path d='M10.7885 3.66914C10.6265 3.66914 10.4712 3.60475 10.3567 3.49015C10.2422 3.37554 10.1779 3.2201 10.1779 3.05803V1.22469H8.34598C8.18403 1.22469 8.02872 1.16031 7.9142 1.0457C7.79969 0.931098 7.73535 0.77566 7.73535 0.613583C7.73535 0.451506 7.79969 0.296068 7.9142 0.181462C8.02872 0.0668567 8.18403 0.00247192 8.34598 0.00247192L10.7885 0.00247192C10.9504 0.00247192 11.1058 0.0668567 11.2203 0.181462C11.3348 0.296068 11.3991 0.451506 11.3991 0.613583V3.05803C11.3991 3.2201 11.3348 3.37554 11.2203 3.49015C11.1058 3.60475 10.9504 3.66914 10.7885 3.66914Z' />
        </svg>
      </>
    );
  }
  if (!href || href === '#') {
    return <Content />;
  }
  return (
    <a href={href} target={target}>
      <Content />
    </a>
  );
};

export default ExternalLinkIcon;
