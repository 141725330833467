import classNames from 'classnames';
import Link from 'next/link';

import ExclamationMarkIcon from '@/components/Icons/ExclamationMarkIcon';

const HiringTag = () => {
  return (
    <Link legacyBehavior href='https://jobs.syfy.io/companies/hatom-labs#content'>
      <a
        className={classNames(
          'whitespace-nowrap text-[10px] font-semibold tracking-wide text-white',
          'bg-black',
          'inline-flex items-center',
          'p-1 px-2',
          'rounded [box-shadow:2px_2px_1px_rgb(0,0,0,.1)]',
        )}
      >
        We're hiring
        <div className='mb-px'>
          <ExclamationMarkIcon />
        </div>
      </a>
    </Link>
  );
};

export default HiringTag;
