import classNames from 'classnames';
import Link from 'next/link';
import { useTranslation } from 'next-i18next';

import css from './styles.module.css';

import useTheme from '@/hooks/useTheme';

import ExternalLinkIcon from '@/components/ExternalLinkIcon';
import ActionButton from '@/components/Header/components/ActionButton';
import Logo from '@/components/Header/components/Logo';
import MegaMenu from '@/components/Header/components/MegaMenu';
import MenuDrawer from '@/components/Header/components/MenuDrawer';
import NetworkSwitcher from '@/components/Header/components/NetworkSwitcher';
import Toggle from '@/components/ThemeToggle';

import clsxm from '@/services/clsxm';

const Header: React.FC<{
  hiringTag?: boolean;
  networkSwitcher?: boolean;
  actionButton?: {
    label: string;
    onClick?: () => void;
  };
}> = ({ hiringTag, networkSwitcher, actionButton }) => {
  const { t } = useTranslation();
  const { isDark } = useTheme();

  return (
    <>
      <header className='relative pt-5 pb-4 font-hass'>
        <MenuDrawer />

        <div className='flex items-center gap-1 sm:gap-4'>
          <Logo
            hiringTag={hiringTag}
            className='relative z-10 mr-5 md:mr-[10.6vw]'
          />

          <nav className={clsxm('relative', css.nav)}>
            <div
              className={classNames(
                'flex gap-5 text-sm font-semibold leading-6 tracking-normal lg:gap-[52px]'
              )}
            >
              <div className='group relative'>
                <a href='#' className='hover:text-blue'>
                  {t('products')}
                </a>

                <div className='pointer-events-none absolute -left-[60px] pt-2 opacity-0 transition-opacity group-hover:pointer-events-auto group-hover:opacity-100'>
                  <MegaMenu />
                </div>
              </div>

              <div className='group relative'>
                <Link legacyBehavior href='/' passHref>
                  <a className='hover:text-blue'>{t('ecosystem')}</a>
                </Link>
              </div>

              <div>
                <a
                  className={classNames(
                    'flex items-center gap-1 hover:text-blue'
                  )}
                  href='https://docs.hatom.com/security/'
                  target='_blank'
                  rel='noreferrer'
                >
                  {t('security')}
                  <ExternalLinkIcon />
                </a>
              </div>
            </div>

            <div className='absolute -right-14 top-1/2 -translate-y-1/2'>
              <Toggle barColor={isDark ? '#fff' : '#06021F'} />
            </div>
          </nav>

          <div className='relative ml-auto flex items-center justify-end gap-2 md:gap-3 lg:gap-4'>
            {networkSwitcher && <NetworkSwitcher />}
            {actionButton && (
              <ActionButton
                label={actionButton?.label}
                onClick={actionButton?.onClick}
              />
            )}
            {/* <Hamburger className={css.hamburger} /> */}
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
