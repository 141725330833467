import DiscordIcon from '@/components/Icons/DiscordIcon';
import MediumIcon from '@/components/Icons/MediumIcon';
import TelegramIcon from '@/components/Icons/TelegramIcon';
import TwitterIcon from '@/components/Icons/TwitterIcon';

import { links } from '@/config/envVars';

const useSocialData = () => {
  return [
    {
      name: 'twitter',
      url: links.twitterURL,
      icon: <TwitterIcon />,
    },
    {
      name: 'telegram',
      url: links.telegramURL,
      icon: <TelegramIcon />,
    },
    {
      name: 'medium',
      url: links.blogURL,
      icon: <MediumIcon />,
    },
    {
      name: 'discord',
      url: links.discordURL,
      icon: <DiscordIcon />,
    },
  ];
};

export default useSocialData;
