import classNames from 'classnames';
import { useTranslation } from 'next-i18next';
import { useHover, useLayer } from 'react-laag';
import SimpleBar from 'simplebar-react';

import styles from './styles.module.scss';

import useIsAgora from '@/hooks/useIsAgora';

import ActiveLink from '@/components/ActiveLink';

import { links } from '@/config/envVars';

const AppsList = () => {
  const agora = useIsAgora();
  const { t } = useTranslation();

  const apps = [
    {
      logo: <img className='h-[28px]' src='https://cdn.app.hatom.com/logo.svg' alt='' />,
      name: 'Hatom Protocol',
      description: t('mega-menu.Hatom Protocol <short>'),
      url: links.hatomUrl,
    },
    {
      logo: (
        <img
          className='h-[28px]'
          src='https://cdn.app.hatom.com/images/hatomlabs.svg'
          alt='hatom labs'
        />
      ),
      name: 'Hatom Labs',
      description: t('mega-menu.Hatom Labs <short>'),
      url: links.hatomLabsURL,
    },
    {
      logo: <img src='https://cdn.app.hatom.com/images/esdt.svg' alt='esdt' />,
      name: 'Esdt',
      description: t('mega-menu.Esdt'),
      url: links.esdtURL,
    },
    {
      logo: <img src='https://cdn.app.hatom.com/images/soul.svg' alt='soul' />,
      name: 'Soul',
      description: t('mega-menu.Soul'),
    },
    {
      logo: <img src='https://cdn.app.hatom.com/images/treasury.svg' alt='treasury' />,
      name: 'Hatom Treasury',
      description: t('mega-menu.Hatom Treasury'),
    },
    {
      logo: <img src='https://cdn.app.hatom.com/images/htmusd.svg' alt='Hatom USD' />,
      name: 'Hatom USD',
      description: t('mega-menu.HatomUSD'),
    },
  ];

  return (
    <SimpleBar style={{ maxHeight: 194 - 8, marginTop: 8 }} autoHide={false}>
      <ul className={styles.Menu}>
        {apps.map((e, idx) => (
          <li key={idx}>
            <SubMenuItemTooltip live={e.url ? true : false}>
              <ActiveLink prevent={!e.url} href={e.url ?? '#'}>
                <a
                  className={`${styles.MenuItem} ${agora && styles.Agora} ${
                    styles.Dark
                  }`}
                >
                  <div>
                    <figure className={`${styles.Logo} ${styles.Dark}`}>
                      {e.logo}
                    </figure>
                  </div>
                  <div className={styles.Text}>
                    <div>{e.name}</div>
                    <div className={styles.Paragraph}>{e.description}</div>
                  </div>
                </a>
              </ActiveLink>
            </SubMenuItemTooltip>
          </li>
        ))}
      </ul>
    </SimpleBar>
  );
};

const SubMenuItemTooltip: React.FC<{
  children: React.ReactNode;
  disabled?: boolean;
  live?: boolean;
}> = ({ children, live }) => {
  const [isOver, hoverProps] = useHover({
    delayEnter: 50,
    delayLeave: 50,
  });

  // eslint-disable-next-line unused-imports/no-unused-vars
  const { triggerProps, layerProps, arrowProps, renderLayer } = useLayer({
    isOpen: isOver,
    placement: 'top-end',
    triggerOffset: -14,
  });
  const { t } = useTranslation();
  return (
    <>
      {isOver &&
        renderLayer(
          <div
            {...layerProps}
            style={{ ...layerProps.style, zIndex: 50, pointerEvents: 'none' }}
          >
            <div
              className={classNames(styles.Tooltip, {
                '!text-white': !live,
              })}
            >
              {live ? t('now-live') : t('coming-soon')}
            </div>
          </div>,
        )}
      <span {...triggerProps} {...hoverProps}>
        {children}
      </span>
    </>
  );
};

export default AppsList;
