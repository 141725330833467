import { useEffect } from 'react';

import useTheme from '@/hooks/useTheme';

const useSetBodyBg = (light = '#fff', dark = '#000') => {
  const { isLight } = useTheme();

  const themeBasedBg = isLight ? light : dark;

  useEffect(() => {
    document.body.style.backgroundColor = themeBasedBg;
    return () => {
      document.body.style.removeProperty('background-color');
    };
  }, [isLight]);
};

export default useSetBodyBg;
