import classNames from 'classnames';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';

import useIsAgora from '@/hooks/useIsAgora';
import useIsGovernancePage from '@/hooks/useIsGovernancePage';
import useIsLiquidStaking from '@/hooks/useIsLiquidStaking';
import useIsMarketsPage from '@/hooks/useIsMarketsPage';
import useTheme from '@/hooks/useTheme';

import {
  ECOSYSTEMS,
  PRODUCTS,
  USEFUL_LINKS,
} from '@/components/Header/constants';

import EcosystemItem from './components/EcosystemItem';
import ProductItem from './components/ProductItem';
import Title from './components/Title';
import UsefulLink from './components/UsefulLink';

const MegaMenu = ({ productsGallery = false }) => {
  const { t } = useTranslation();
  const { isLight, isDark } = useTheme();
  const isGovernancePage = useIsGovernancePage();
  const isMarketPage = useIsMarketsPage();
  const agora = useIsAgora() || (isLight && (isGovernancePage || isMarketPage));
  const liquidStaking = useIsLiquidStaking();

  const { pathname } = useRouter();

  return (
    <div
      className={classNames('pt-2 text-[10px] leading-tight tracking-normal')}
    >
      <div
        className={classNames(
          'rounded-2xl border',
          pathname === '/lend' && 'backdrop-blur-[3.5px]',
          isMarketPage || isGovernancePage
            ? {
                ['border-[#06021F] bg-white']: isLight,
                ['border-[#262469]']: !isLight,
              }
            : liquidStaking
            ? {
                ['border-[#06021f] bg-[#f7f7fa] dark:border-[#2E2C51]']: true,
                ['dark:bg-[#191733]']: !isLight,
              }
            : agora
            ? {
                ['border-[#06021F] bg-white']: isLight,
                ['border-[#233070]']: !isLight,
              }
            : ['border-[#262469]']
        )}
        style={
          isMarketPage || isGovernancePage
            ? {
                ...(!isLight
                  ? {
                      background:
                        'linear-gradient(122.54deg, #151336 -12.89%, #0B423F 101.09%)',
                    }
                  : {}),
              }
            : liquidStaking
            ? {}
            : agora
            ? {
                ...(!isLight
                  ? {
                      background:
                        'linear-gradient(122.54deg, #151336 -12.89%, #1D317A 101.09%)',
                    }
                  : {}),
              }
            : {
                background:
                  'linear-gradient(122.54deg, rgba(21,19,54,0.8) -12.89%, rgba(11,66,63,0.8) 101.09%)',
              }
        }
      >
        {productsGallery ? (
          <div
            className={classNames(
              'grid grid-cols-[repeat(4,136px)] gap-5',
              agora || liquidStaking
                ? isLight
                  ? 'text-[#06021F]'
                  : 'text-[#CED1EF]'
                : 'text-[#929EAC]',
              'font-normal',
              'p-7'
            )}
          >
            {ECOSYSTEMS.map((e) => {
              if (e.title.includes('Liquid Staking')) {
                return { ...e, title: 'Liquid staking black tear' };
              }
              return e;
            }).map((item, i) => (
              <EcosystemItem
                key={i}
                {...item}
                description={t(`mega-menu.${item.title}`)}
              />
            ))}
          </div>
        ) : (
          <div
            className={classNames(
              'flex flex-row gap-[30px]',
              liquidStaking
                ? isDark
                  ? 'text-[#B5B1E2]'
                  : 'text-[#06021F]'
                : agora && isLight
                ? 'text-[#06021F]'
                : 'text-[#6C7DA6]',
              'p-5'
            )}
          >
            <div className='min-w-[242px] space-y-3'>
              {[...PRODUCTS].map((item, i) => (
                <ProductItem
                  key={i}
                  {...item}
                  description={t(`mega-menu.${item.title}`)}
                />
              ))}
            </div>
            <div>
              <div
                className={classNames(
                  'h-full border-l',
                  liquidStaking
                    ? isLight
                      ? 'border-[#B8B8B8]'
                      : 'border-[#2A264A]'
                    : agora
                    ? isLight
                      ? 'border-[#B8B8B8]'
                      : 'border-[#2D3873]'
                    : 'border-[#2D3873]'
                )}
              ></div>
            </div>
            <div className='mr-5 min-w-[129px] pt-2'>
              <Title
                className={classNames(
                  liquidStaking
                    ? isDark && 'text-[#B5B1E2]'
                    : agora
                    ? isDark && 'text-[#525BA0]'
                    : 'text-[#59828F]'
                )}
              >
                {t('useful-links')}:
              </Title>
              <div className='mt-5 space-y-[18px]'>
                {USEFUL_LINKS.filter(
                  (item) => item.hiddenOn !== 'megamenu'
                ).map((item) => {
                  return (
                    <div className='-mx-2' key={item.name}>
                      <UsefulLink {...item} />
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default MegaMenu;
