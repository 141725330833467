import classNames from 'classnames';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';

import useIsAgora from '@/hooks/useIsAgora';
import useTheme from '@/hooks/useTheme';

import { useAppDispatch } from '@/store';
import { setDrawer } from '@/store/drawer';

import { capitalizeFirstLetter, slugify } from '@/utils/helpers';

type BottomLinkProps = {
  name: string;
  url: string;
  external?: boolean;
};

const BottomLink = ({ name, url, external }: BottomLinkProps) => {
  const { t } = useTranslation();
  const agora = useIsAgora();
  const { isLight, isDark } = useTheme();
  const router = useRouter();
  const dispatch = useAppDispatch();

  return (
    <a
      className={classNames(
        'tracking-wide',
        'flex justify-between gap-2',
        'rounded-[8px] p-2 transition-colors hover:bg-[#06192D]/10 hover:dark:bg-[#06192D]'
      )}
      href={url}
      target={external ? '_blank' : ''}
      rel='noopener noreferrer'
      onClick={(e) => {
        e.preventDefault();
        if (external) {
          window.open(url, '_ blank');
        } else {
          router.push(url);
        }
        dispatch(setDrawer({ isOpen: false }));
      }}
    >
      <img
        className={classNames(agora && isLight && 'opacity-20 brightness-0')}
        src={`https://cdn.app.hatom.com/images/${slugify(name) + (agora && isDark ? '-agora' : '')}.svg`}
        alt={name}
      />
      <h5 className={classNames(agora && 'text-[#393646] dark:text-[#49477C]')}>
        {capitalizeFirstLetter(t(name))}
      </h5>
    </a>
  );
};

export default BottomLink;
