import classNames from 'classnames';
import React from 'react';
import { isDesktop } from 'react-device-detect';

import useTheme from '@/hooks/useTheme';

type ToggleProps = {
  barColor?: any;
  iconColor?: string;
  darkBackground?: any;
};

const Toggle = ({ barColor, iconColor, darkBackground }: ToggleProps) => {
  const { isDark, isLight, setTheme } = useTheme();

  function toggle() {
    const themeValueUpdated = isDark ? 'light' : 'dark';
    setTheme(themeValueUpdated);
  }

  const ICON_CLASS = [
    'absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2',
  ];

  function Star() {
    return (
      <div className='relative'>
        <div
          className={classNames(
            'h-3.5 w-3.5',
            'relative rounded-full shadow transition-all',
            'bg-green'
          )}
        >
          {isLight ? (
            <svg
              className={classNames(ICON_CLASS, 'text-white')}
              xmlns='http://www.w3.org/2000/svg'
              width='8'
              height='8'
              fill='none'
              viewBox='0 0 8 8'
            >
              <path
                fill={iconColor ? iconColor : 'currentColor'}
                fillRule='evenodd'
                d='M4 2.383c.316 0 .624.095.887.272.262.178.467.43.587.726a1.637 1.637 0 01-.346 1.762 1.576 1.576 0 01-1.74.35 1.6 1.6 0 01-.715-.595 1.633 1.633 0 01.199-2.041c.299-.304.705-.474 1.128-.474zM4 0c.132 0 .258.053.35.147a.506.506 0 01.146.356V1a.506.506 0 01-.145.356.493.493 0 01-.702 0A.506.506 0 013.504 1V.503c0-.134.052-.261.145-.356A.493.493 0 014 0zm0 6.503c.13 0 .256.052.349.145.093.093.146.22.147.352v.497a.506.506 0 01-.145.356.493.493 0 01-.702 0 .506.506 0 01-.145-.356V7a.506.506 0 01.147-.352A.493.493 0 014 6.503zm-3.451-3h.49a.494.494 0 01.317.164.507.507 0 01-.316.836H.549a.49.49 0 01-.506-.295.509.509 0 01.3-.684.49.49 0 01.206-.021zm6.411 0h.491a.49.49 0 01.506.295.509.509 0 01-.3.683.49.49 0 01-.206.022h-.49a.494.494 0 01-.317-.164.507.507 0 01.316-.836zM1.21 1.17a.493.493 0 01.699 0l.35.355a.504.504 0 01.144.493.505.505 0 01-.359.364.479.479 0 01-.485-.154l-.35-.349a.506.506 0 010-.709zm4.51 4.6a.493.493 0 01.7 0l.35.349a.506.506 0 01-.32.872.493.493 0 01-.357-.135l-.35-.354a.506.506 0 010-.703l-.022-.029zm-4.488.378l.35-.349a.496.496 0 01.485-.154.495.495 0 01.359.364.51.51 0 01-.145.493l-.372.354a.492.492 0 01-.67-.029.506.506 0 01-.03-.68h.023zM5.72 1.577l.35-.354a.493.493 0 01.854.298.508.508 0 01-.105.359l-.35.349a.479.479 0 01-.713.022.504.504 0 01.015-.725'
                clipRule='evenodd'
              ></path>
            </svg>
          ) : (
            <svg
              className={classNames(ICON_CLASS, 'text-[#021428]')}
              xmlns='http://www.w3.org/2000/svg'
              width='10'
              height='10'
              fill='none'
              viewBox='0 0 10 10'
            >
              <path
                fillRule='evenodd'
                stroke={iconColor ? iconColor : 'currentColor'}
                strokeMiterlimit='10'
                strokeWidth='0.572'
                d='M8.315 5.62A3.077 3.077 0 014.38 1.685a.532.532 0 00-.12-.537.532.532 0 00-.538-.12 4.106 4.106 0 105.25 5.25.509.509 0 00-.657-.652V5.62z'
                clipRule='evenodd'
              ></path>
            </svg>
          )}
        </div>
        <div
          className={classNames(
            'absolute-xy-center flex-xy-center aspect-square w-6 rounded-full transition-colors sm:w-[34px]',
            isDesktop && {
              'hover:bg-white/5': darkBackground,
              'hover:bg-black/5 dark:hover:bg-white/5': !darkBackground,
            }
          )}
        ></div>
      </div>
    );
  }

  return (
    <div className='ThemeToggle rounded-full p-2 transition duration-500 ease-in-out'>
      <button className='Switcher -m-4 p-4' onClick={toggle}>
        <div className='relative' style={{ width: 28 }}>
          <div
            className={`absolute top-1/2 -translate-y-1/2 transition-all ${
              isLight ? '-left-0' : 'left-[14px]'
            }`}
          >
            <Star />
          </div>

          <div
            style={{ height: 8, ...(barColor ? { background: barColor } : {}) }}
            className={`w-full rounded-xl  ${
              !barColor && isDark ? 'bg-[#102337]' : 'bg-indigo-100'
            }`}
          ></div>
        </div>
      </button>
    </div>
  );
};

export default Toggle;
