export default function ArrowRightIcon(
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
) {
  return (
    <svg
      width={10}
      height={6}
      viewBox='0 0 10 6'
      fill='none'
      stroke='currentColor'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M9.31348 3.05365H1.06348'
        strokeWidth='1.375'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M7.25098 5.11615L9.31348 3.05365L7.25098 0.99115'
        strokeWidth='1.375'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}
