import clsxm from '@/services/clsxm';

export const HEADER_CTA_BTN_CLASSES = clsxm(
  'relative min-w-[80px] overflow-hidden rounded-md',
  'p-2 py-1.5',
  'flex items-center justify-center',
  'text-superxs font-semibold capitalize leading-tight',
  '[box-shadow:0px_0px_0px_var(--thikness)_currentColor_inset] [--thikness:1px]',
  'md:rounded-[8px] md:py-1.5 md:px-5 md:text-[14px] md:leading-6',
  'lg:px-8 lg:py-2 lg:text-[16px] lg:[--thikness:2px]'
);

const ActionButton: React.FC<{
  label: string;
  className?: string;
  onClick?: () => void;
}> = ({ label, onClick, className }) => {
  return (
    <button
      onClick={onClick}
      className={clsxm('btnHover', HEADER_CTA_BTN_CLASSES, className)}
    >
      {label}
    </button>
  );
};

export default ActionButton;
