import { useTranslation } from 'next-i18next';

import { links } from '@/config/envVars';

const useLinksData = () => {
  const { t } = useTranslation('translation');
  return [
    { name: t('terms'), path: `${links.mainnetURL}/terms`, external: true },
    {
      name: t('documentation'),
      path: links.docURL,
      external: true,
    },
  ];
};

export default useLinksData;
