import React from 'react';

const TelegramIcon = () => {
  return (
    <svg
      width={14}
      height={12}
      viewBox='0 0 14 12'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M13.1953 0.30331L0.937524 4.23793C0.735507 4.30278 0.710487 4.57848 0.89753 4.67863L2.89679 5.74914C3.19098 5.90667 3.52149 5.9839 3.85503 5.97306C4.08023 5.96574 4.30047 5.90511 4.49768 5.79614L10.1681 2.66298C10.3362 2.5701 10.54 2.70216 10.5238 2.89354L10.5192 2.94874C10.5135 3.01647 10.4793 3.07861 10.4253 3.11977L4.92367 7.30757C4.89635 7.32837 4.87874 7.35946 4.87494 7.39359L4.48904 10.8667C4.47032 11.0352 4.6742 11.1332 4.79406 11.0133L7.43911 8.36826C7.51656 8.29082 7.63656 8.27553 7.73095 8.33108L9.38808 9.30635C9.40041 9.3136 9.41206 9.32194 9.4229 9.33126L9.90662 9.74729C10.3076 10.0921 10.931 9.9023 11.0717 9.39254L13.5004 0.596101C13.5512 0.411913 13.3772 0.24491 13.1953 0.30331Z'
        fill='currentColor'
        stroke='currentColor'
        strokeWidth='0.240331'
      />
    </svg>
  );
};

export default TelegramIcon;
