import { SVGProps } from 'react';

const ExclamationMarkIcon = (
  props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>
) => {
  return (
    <svg
      width='4'
      height='12'
      viewBox='0 0 4 12'
      fill='currentColor'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path d='M1.008 3.072V0.0959997H3.616V3.072L2.96 7.856H1.664L1.008 3.072ZM0.976 9.376H3.648V12H0.976V9.376Z' />
    </svg>
  );
};

export default ExclamationMarkIcon;
