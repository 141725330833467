import classNames from 'classnames';
import { useTranslation } from 'next-i18next';

import useIsAgora from '@/hooks/useIsAgora';
import useIsGovernancePage from '@/hooks/useIsGovernancePage';
import useIsLiquidStaking from '@/hooks/useIsLiquidStaking';
import useIsMarketsPage from '@/hooks/useIsMarketsPage';
import useTheme from '@/hooks/useTheme';

import CustomLink from '@/components/CustomLink';
import Hint from '@/components/Hint';

import { capitalizeFirstLetter, slugify } from '@/utils/helpers';

import Title from './Title';

const UsefulLink: React.FC<any> = ({ name, url, external = false, logo }) => {
  const { t } = useTranslation();
  const isGovernancePage = useIsGovernancePage();
  const isMarketPage = useIsMarketsPage() || isGovernancePage;
  const agora = useIsAgora() || isMarketPage;
  const liquidStaking = useIsLiquidStaking();
  const { isLight, isDark } = useTheme();
  const live = url !== '#';

  return (
    <Hint
      block
      content={
        <span className='text-[10px] font-semibold leading-none tracking-wide'>
          {t('coming-soon')}
        </span>
      }
      layerStyle={{
        display: 'inline-flex',
        padding: '6px',
        borderRadius: 6,
        translate: '-8px 0',
      }}
      unvisible={live}
      placement='top-end'
      backgroundColor='#030303'
      offset={-7}
    >
      <CustomLink href={url} external={external}>
        <span
          className={classNames(
            { 'cursor-default': !live },
            'flex justify-between gap-1',
            'rounded-[8px] p-2 transition-colors',
            liquidStaking
              ? isLight
                ? 'hover:bg-[#EEEBF8]'
                : 'hover:bg-[#45427066]'
              : agora
              ? isDark
                ? 'hover:bg-[#090c2ea3]'
                : 'hover:bg-[#ecebebb7]'
              : 'hover:bg-[#06192D]',
            'whitespace-nowrap'
          )}
        >
          <Title>{capitalizeFirstLetter(t(name))}</Title>
          <img
            className={classNames(
              (agora || liquidStaking) && isLight
                ? 'brightness-0'
                : 'hue-rotate-[390deg] saturate-150'
            )}
            src={`https://cdn.app.hatom.com/images/mega/${logo ? logo : slugify(name) + '.svg'}`}
            alt={name}
          />
        </span>
      </CustomLink>
    </Hint>
  );
};

export default UsefulLink;
