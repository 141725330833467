import classNames from 'classnames';
import { useRouter } from 'next/router';
import { useState } from 'react';
import { Arrow, useLayer } from 'react-laag';

import usePreloadImages from '@/hooks/usePreloadImages';
import useTheme from '@/hooks/useTheme';

import GlobeIcon from './componetns/LanguageIcons/GlobeIcon';

const LanguageSwitcher = ({ onlyDark = false }) => {
  const router = useRouter();

  const list = [
    ['English', 'https://cdn.app.hatom.com/images/english.png', 'en'],
    ['French', 'https://cdn.app.hatom.com/images/french.png', 'fr'],
    ['Chinese', 'https://cdn.app.hatom.com/images/chinese.png', 'zh'],
    ['Spanish', 'https://cdn.app.hatom.com/images/spanish.png', 'es'],
    ['Korean', 'https://cdn.app.hatom.com/images/korean.png', 'ko'],
    ['Romanian', 'https://cdn.app.hatom.com/images/romanian.png', 'ro'],
    ['Portuguese', 'https://cdn.app.hatom.com/images/portuguese.png', 'pt'],
  ];

  const changeLanguage = (e: any) => {
    router.push(router.asPath, router.asPath, { locale: e[2] });
  };

  const [isOpen, setOpen] = useState(false);
  const { isDark } = useTheme();
  const { renderLayer, triggerProps, layerProps, arrowProps } = useLayer({
    isOpen,
    auto: true,
    possiblePlacements: ['top-center'],
    triggerOffset: 8,
    containerOffset: 4,
    arrowOffset: 8,
    onOutsideClick: () => setOpen(false),
  });

  usePreloadImages(list.map(([, url]) => url));

  const theme = {
    light: {
      container: 'rgba(169,183,215,0.25)',
      flagSahdow: '[--c:#7b8db4]',
      defaultItem: 'hover:text-[#2A3E6C] hover:bg-[#FFFFFF75]',
      itemUnselected: 'text-[#53648C]',
      itemSelected: 'text-[#2A3E6C] bg-[#FFFFFF75]',
    },
    dark: {
      container: 'rgba(169,183,215,0.1)',
      flagSahdow: '[--c:#000]',
      defaultItem: 'text-white hover:bg-[#ffffff2d]',
      itemUnselected: '',
      itemSelected: 'text-white bg-[#ffffff2d]',
    },
  };

  const skin = theme[onlyDark || isDark ? 'dark' : 'light'];

  return (
    <div className='relative'>
      {isOpen &&
        renderLayer(
          <div
            {...layerProps}
            className={classNames(
              'pointer-events-auto flex flex-col items-center'
            )}
          >
            <div
              style={{ background: skin.container }}
              className={classNames(
                'rounded-xl px-2 py-3',
                'backdrop-blur-[1.5px]'
              )}
            >
              <ul className='space-y-1.5'>
                {list.map((e, i) => {
                  const selected = e[2].toLowerCase() === router.locale;
                  return (
                    <li
                      onClick={() => changeLanguage(e)}
                      key={i}
                      className={classNames(
                        skin.defaultItem,
                        {
                          [skin.itemSelected]: selected,
                          [skin.itemUnselected]: !selected,
                        },
                        'rounded-2xl p-0.5',
                        'cursor-pointer',
                        'text-superxs font-normal leading-tight tracking-normal',
                        'grid grid-cols-[12px_1fr] gap-3'
                      )}
                    >
                      <img
                        src={e[1]}
                        className={classNames(
                          'overflow-hidden rounded-full [box-shadow:0.5px_0.5px_var(--c)]',
                          skin.flagSahdow
                        )}
                        alt=''
                      />
                      <p>{e[0]}</p>
                    </li>
                  );
                })}
              </ul>
            </div>

            {/* Arrow */}
            <Arrow
              backgroundColor={skin.container}
              {...arrowProps}
              size={8}
              roundness={1}
            />
          </div>
        )}
      <button
        {...triggerProps}
        onClick={() => setOpen(!isOpen)}
        className='flex items-center justify-end gap-1.5 rounded p-1  text-[10px] capitalize hover:bg-[rgba(169,_183,_215,_0.1)]'
      >
        <GlobeIcon />
      </button>
    </div>
  );
};

export default LanguageSwitcher;
