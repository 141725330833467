import classNames from 'classnames';
import { useTranslation } from 'next-i18next';

import useMediaQuery from '@/hooks/useMediaQuery';
import useToggle from '@/hooks/useToggle';

import { HEADER_CTA_BTN_CLASSES } from '@/components/Header/components/ActionButton';
import { Lamp } from '@/components/Header/components/NetworkSwitcher/Lamp';
import { NETWORKS_LIST } from '@/components/Header/constants';

import { chainType } from '@/config/network';
import clsxm from '@/services/clsxm';

const NetworkSwitcher = () => {
  const { t } = useTranslation();
  const md = useMediaQuery('(min-width: 768px)');
  const lg = useMediaQuery('(min-width: 1024px )');
  const agora = true;
  const [on, toggle, set] = useToggle();

  return (
    <div
      className='group relative'
      onMouseEnter={(_) => {
        set(true);
      }}
      onMouseLeave={(_) => {
        set(false);
      }}
    >
      <button
        className={clsxm(
          'btnHover',
          HEADER_CTA_BTN_CLASSES,
          'gap-1 py-1',
          'lg:w-[154px] lg:gap-2.5 lg:px-0'
        )}
      >
        <div>
          <img
            className='h-4 md:h-5 lg:h-6'
            src='https://cdn.app.hatom.com/images/multiverx.svg'
            alt='Multiverx'
          />
        </div>
        <div className='flex items-center gap-1 text-left md:gap-2 lg:gap-4'>
          {chainType}
          <div>
            <Lamp on className='h-[3px] w-[3px] md:h-1.5 md:w-1.5' />
          </div>
        </div>
      </button>

      <div
        className={classNames(
          {
            hidden: !on,
          },
          'absolute flex w-[111%] flex-col items-start',
          'pt-2'
        )}
      >
        <div
          className={clsxm(
            HEADER_CTA_BTN_CLASSES,
            'flex-col items-start',
            'p-1.5 px-1',
            'md:p-2 md:px-3',
            'lg:p-2.5 lg:px-4',
            'backdrop-blur-sm'
          )}
        >
          <div
            className={classNames(
              'text-[6px] font-normal leading-tight',
              'md:text-superxs lg:leading-6',
              'lg:text-[12px]',
              !agora && 'text-[#91BBC1]'
            )}
          >
            {t('select-a-network')}
          </div>
          <ul
            className={classNames(
              'space-y-2',
              'md:mt-2 md:space-y-3 lg:space-y-5'
            )}
          >
            {NETWORKS_LIST.map((item, i) => {
              return (
                <li key={i}>
                  <a
                    href='#'
                    onClick={(e) => {
                      e.preventDefault();
                      toggle();
                    }}
                  >
                    <div
                      className={classNames(
                        'flex items-center gap-1 rounded p-0.5 pr-1 font-semibold',
                        'md:gap-1 md:rounded-[8px] md:p-0.5 md:pr-2 md:leading-6',
                        'lg:gap-2.5 lg:p-1 lg:pr-2',
                        agora
                          ? 'hover:bg-[#D3D9FF] dark:hover:bg-white dark:hover:text-black'
                          : 'hover:bg-[#21655651]',
                        {
                          'cursor-not-allowed': chainType != item.value,
                        }
                      )}
                    >
                      <img
                        className='h-4 md:h-6'
                        src='https://cdn.app.hatom.com/images/multiverx.svg'
                        alt='MultiverX'
                      />
                      <div className='min-w-[40px] md:min-w-[54px] lg:min-w-[62px]'>
                        {item.text}
                      </div>
                      <div className={classNames('lg:ml-2.5')}>
                        <Lamp
                          on={chainType === item.value}
                          className='h-1 w-1 md:h-1.5 md:w-1.5'
                        />
                      </div>
                    </div>
                  </a>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default NetworkSwitcher;
