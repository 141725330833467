const GlobeIcon = () => {
  return (
    <svg
      width={16}
      height={16}
      viewBox='0 0 10 10'
      fill='none'
      stroke='currentColor'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M5.6844 0.917969H4.60271C2.52238 0.917969 0.835938 2.60441 0.835938 4.68474V5.76643C0.835938 7.84676 2.52238 9.5332 4.60271 9.5332H5.6844C7.76473 9.5332 9.45117 7.84676 9.45117 5.76643V4.68474C9.45117 2.60441 7.76473 0.917969 5.6844 0.917969Z'
        strokeWidth='0.797707'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M5.30858 9.5332C4.6789 9.19248 4.15301 8.68792 3.78651 8.07288C3.42002 7.45785 3.22655 6.7552 3.22656 6.03925V4.41193C3.2271 3.69667 3.42028 2.99476 3.7858 2.37995C4.15132 1.76514 4.67568 1.26012 5.30379 0.917969'
        strokeWidth='0.797707'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M4.66797 9.5332C5.29765 9.19248 5.82354 8.68792 6.19003 8.07288C6.55653 7.45785 6.75 6.7552 6.74998 6.03925V4.41193C6.74897 3.69618 6.55508 2.99395 6.18869 2.37909C5.8223 1.76424 5.29697 1.25949 4.66797 0.917969'
        strokeWidth='0.797707'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M9.45117 5.22656H0.835938'
        strokeWidth='0.797707'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default GlobeIcon;
