import classNames from 'classnames';
import { useTranslation } from 'next-i18next';
import { useToggle } from 'usehooks-ts';

import useIsAgora from '@/hooks/useIsAgora';
import useIsGovernancePage from '@/hooks/useIsGovernancePage';
import useIsLiquidStaking from '@/hooks/useIsLiquidStaking';
import useIsMarketsPage from '@/hooks/useIsMarketsPage';
import useTheme from '@/hooks/useTheme';

import CustomLink from '@/components/CustomLink';
import Hint from '@/components/Hint';

import { slugify } from '@/utils/helpers';

import ArrowRight from '../Icons/ArrowRight';
import Title from './Title';

const EcosystemItem: React.FC<any> = ({ title, description, url, active }) => {
  const [hoverOn, toggle] = useToggle();
  const live = active || url !== '#';
  const { t } = useTranslation();
  const { isLight, isDark } = useTheme();
  const isGovernancePage = useIsGovernancePage();
  const isMarketPage = useIsMarketsPage();
  const agora = useIsAgora() || (isLight && (isGovernancePage || isMarketPage));
  const liquidStaking = useIsLiquidStaking();

  return (
    <div onMouseEnter={toggle} onMouseLeave={toggle}>
      <CustomLink href={url} external={live && !active}>
        <Hint
          block
          content={
            <span className='text-[10px] font-semibold leading-none tracking-wide'>
              {t('coming-soon')}
            </span>
          }
          layerStyle={{
            display: 'inline-flex',
            padding: '6px',
            borderRadius: 6,
            translate: '-8px 0',
          }}
          unvisible={live}
          placement='top-end'
          backgroundColor='#030303'
          arrow={false}
          offset={-12}
        >
          <div
            className={classNames(
              'relative min-h-[167px] rounded-[8px] p-2',
              '[box-shadow:0_0_0_1px_var(--c)]',
              liquidStaking
                ? isDark
                  ? '[--c:#7D79AB] hover:bg-[#45427066] hover:[--c:#45427066]'
                  : 'bg-white/60 [--c:#F1EFF9] hover:bg-[#F1EFF9] hover:[--c:#fff0]'
                : agora
                ? isDark
                  ? '[--c:#4049AD] hover:[--c:#fff0] hover:[background-image:linear-gradient(157.63deg,#302F8D_5.58%,#131765_104.77%)]'
                  : 'bg-[rgba(239,239,239,0.6)] [--c:#E2DFDF] hover:bg-[#ECEBEB] hover:[--c:#fff0]'
                : '[--c:black] hover:bg-[#06192D] hover:[--c:#06192D]'
            )}
          >
            <div
              className={classNames(
                'inline-block rounded-[8px]',
                liquidStaking
                  ? isLight
                    ? hoverOn
                      ? 'bg-white'
                      : 'bg-[#EEEBF8]'
                    : 'bg-[#1F1B3D]'
                  : agora
                  ? isLight
                    ? hoverOn
                      ? 'bg-[#D6D5D5]'
                      : 'bg-[#E8E8E8]'
                    : hoverOn
                    ? 'bg-[#04042F]'
                    : 'bg-[#060825]'
                  : 'bg-[#021321]'
              )}
            >
              <img
                className='h-10 w-10'
                src={`https://cdn.app.hatom.com/images/mega/${slugify(title)}.svg`}
                alt={title}
              />
            </div>

            <Title className='mt-5'>{title}</Title>
            <p className='mt-2'>{description}</p>
            <div className='absolute bottom-2.5 right-3'>
              <ArrowRight
                className={classNames(
                  'transition-colors',
                  hoverOn
                    ? 'text-[#43A077]'
                    : liquidStaking && isDark
                    ? 'text-white'
                    : agora && isDark
                    ? 'text-[#4049AD]'
                    : 'text-[#06192D]'
                )}
              />
            </div>
          </div>
        </Hint>
      </CustomLink>
    </div>
  );
};

export default EcosystemItem;
