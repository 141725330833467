import classNames from 'classnames';
import { useTranslation } from 'next-i18next';

import ProductItem from '@/components/Header/components/MenuDrawer/components/ProductItem';
import { PRODUCTS } from '@/components/Header/constants';
import Toggle from '@/components/ThemeToggle';

const update = (array: Record<string, any>[], i: number, props = {}) => {
  const target = array[i];
  for (const [key, value] of Object.entries(props)) {
    target[key] = value;
  }
  return array;
};
const PRODUCT_LIST = update([...PRODUCTS], 0, {
  forceLabel: 'Lending Protocol',
}) as {
  active?: boolean;
  description: string;
  forceLabel?: string;
  title: string;
  url: string;
  force?: boolean;
}[];

function Products() {
  const { t } = useTranslation();
  return (
    <div className='relative px-8 '>
      <ul className='mx-auto flex max-w-[270px] flex-col gap-2 text-sm font-semibold leading-tight'>
        {PRODUCT_LIST.map((item, i) => (
          <li key={item.title}>
            <ProductItem {...item} description={t(`mega-menu.${item.title}`)} />
          </li>
        ))}
        <li className={classNames('mx-auto mt-3')}>
          <Toggle barColor='#182F47' />
        </li>
      </ul>
    </div>
  );
}

export default Products;
