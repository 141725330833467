import Link from 'next/link';
import { useRouter } from 'next/router';

import css from '../../styles.module.css';

import useIsAgora from '@/hooks/useIsAgora';

import { setDrawer } from '@/store/drawer';
import { useAppDispatch } from '@/store/index';

import { links } from '@/config/envVars';
import clsxm from '@/services/clsxm';

import HiringTag from './components/HiringTag';

const Logo = ({ className = '', hiringTag = false }) => {
  const router = useRouter();
  const agora = useIsAgora();
  const href = !agora ? '/' : `${links.hatomUrl}/${router.locale}`;
  const dispatch = useAppDispatch();

  return (
    <div
      className={clsxm('logo', 'relative', css.menuDrawerBringFront, className)}
      onClick={() => {
        dispatch(setDrawer({ isOpen: false }));
      }}
    >
      <Link legacyBehavior href={href} passHref>
        <a>
          <img src='https://cdn.app.hatom.com/logo.svg' alt='Logo' className='h-8 lg:h-11' />
        </a>
      </Link>
      {hiringTag && (
        <div className='absolute left-10 -top-2 origin-left scale-[.8] md:left-12 md:scale-100 lg:left-14'>
          <HiringTag />
        </div>
      )}
    </div>
  );
};

export default Logo;
